/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import { grid, layoutWrapper } from '@mixins';
import RichText from '@core/RichText';
import Image from '@core/Image';
import Link from '@core/Link';
import isBrowser from '@utils/isBrowser';
import Seo from '@core/Seo';

const ProjectPage = ({
  data: {
    contentfulProject: {
      subtitle,
      coverImage,
      author,
      images,
      description,
      title,
      details,
      quote,
      slugifiedTitle,
      seoImage,
    },
  },
}) => {
  useEffect(() => {
    if (isBrowser) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <Seo
        pageTitle={title}
        pageDescription={documentToPlainTextString(JSON.parse(description.raw))}
        pageImage={seoImage}
      />
      <Main>
        <ImageList>
          <li>
            <Image image={coverImage} />
          </li>
          {images.map((image, index) => (
            <li key={index}>
              <Image image={image} loading="eager" />
            </li>
          ))}
        </ImageList>
        <Title>
          {title && <div>{title}</div>}
          {author && <div>{author}</div>}
          {subtitle && <div>{subtitle}</div>}
        </Title>
        <Body>
          <Details text={details} />
          <Quote text={quote} />
          <Description text={description} />
        </Body>
        <BackButton to={`/#${slugifiedTitle}`}>← Back</BackButton>
      </Main>
    </>
  );
};

const Main = styled.main`
  margin-top: 5rem;
  margin-bottom: 6.25rem;
`;

const ImageList = styled.ul`
  ${grid};
  row-gap: 0.25rem;

  li {
    grid-column: 1 / -1;
  }
`;

const Body = styled.div`
  margin-bottom: 5rem;
  display: grid;
  row-gap: 3rem;
  ${layoutWrapper};
`;

const Title = styled.div`
  ${layoutWrapper}
  text-align: center;
  margin-top: 2.375rem;
  margin-bottom: 1em;
`;

const Details = styled(RichText)`
  text-align: center;
`;

const Quote = styled(RichText)`
  text-align: center;

  blockquote {
    font-style: italic;
    font-feature-settings: 'ss01';
    margin-bottom: 1em;
  }
`;

const Description = styled(RichText)`
  /* text-align: center; */
`;

const BackButton = styled(Link)`
  ${layoutWrapper};
  text-align: center;
  display: block;
`;

export const query = graphql`
  query ($id: String!) {
    contentfulProject(id: { eq: $id }) {
      title
      author
      subtitle
      slugifiedTitle
      description {
        raw
      }
      details {
        raw
      }
      quote {
        raw
      }
      coverImage {
        gatsbyImageData(
          layout: CONSTRAINED
          breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
          quality: 75
        )
      }
      images {
        id
        gatsbyImageData(
          layout: CONSTRAINED
          breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
          quality: 75
        )
      }
      seoImage: coverImage {
        gatsbyImageData(
          formats: [JPG]
          width: 1200
          height: 630
          resizingBehavior: FILL
          quality: 75
        )
      }
    }
  }
`;

export default ProjectPage;
